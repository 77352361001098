import React, { FunctionComponent } from 'react';

import { Typography } from '@material-ui/core';

interface AddressProps {
  address?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  county?: string;
  country?: string;
}

export const Address: FunctionComponent<AddressProps> = ({ address, address2, postalCode, city, county, country }) =>
  <Typography variant="body2" component="p">
    {address && <>{address}<br /></>}
    {address2 && <>{address2}<br /></>}
    {postalCode || city ? <>{postalCode} {city}<br /></> : null}
    {county && country ? `${county}, ${country}` : `${county || ''}${country || ''}`}
  </Typography>;
import { styled } from "@material-ui/styles";

export const StepButtonRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1em',
  marginBottom: '1em',

  '& > div > button:first-child': {
    marginRight: '0.5em',
  },
});

export const Warning = styled('blockquote')({
  margin: '24px 0',
  padding: '12px 24px',
  borderLeft: '5px solid #ffe564',
  backgroundColor: 'rgba(255,229,100,0.2)',
});

export const Error = styled('blockquote')({
  margin: '24px 0',
  padding: '12px 24px',
  borderLeft: '5px solid #f00',
  backgroundColor: 'rgb(255,200,200)',
});

export const ProgressPlaceholder = styled('div')({
  height: '4px',
  overflow: 'hidden',
  position: 'relative',
});


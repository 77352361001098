import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { ProgressPlaceholder, Warning } from '../styled';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { each, isArray } from 'lodash';
import { fetchData, setPage, setRowsPerPage, setSearchTerm } from '../../state/products/actions';
import { getData, getError, getPage, getRowsPerPage, getSearch, isLoading } from '../../state/products/selectors';

import { Action } from 'redux';
import { BuyButton } from '../BuyButton';
import { PagingResult } from '../../services/BackendService';
import { Product } from '../../types';
import { SearchField } from '../SearchField';
import { TextWithSubtext } from '../TextWithSubtext';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';

interface ProductSelectorProps {
  fetchData: Function;
  error: any;
  data: PagingResult<any>;
  loading: boolean;
  rowsPerPage: number;
  page: number;
  search: string;
  setPage: Function;
  setRowsPerPage: Function;
  setSearch: Function;
  onShoppingCartChanged: Function;
  initialShoppingCart?: any;
}

const ProductSelector: FunctionComponent<ProductSelectorProps> = ({
  fetchData,
  error,
  data,
  loading,
  rowsPerPage,
  page,
  search,
  setPage,
  setRowsPerPage,
  setSearch,
  onShoppingCartChanged,
  initialShoppingCart,
}) => {
  const { t } = useTranslation();
  const [shoppingCart, setShoppingCart] = useState<any>(initialShoppingCart || {});
  useMount(() => fetchData());

  const handleChangePage = (event: any, page: number) => setPage(page);
  const handleChangeRowsPerPage = (event: any) => setRowsPerPage(event.target.value);

  const onQuantityChanged = (product: Product, quantity: number) => {
    const filtered = { ...shoppingCart, [product.no]: {
      id: product.id,
      no: product.no,
      description: product.description,
      description2: product.description2,
      quantity: quantity,
    }};
    // remove empty entries from shopping cart
    each(filtered, (value, key) => { if (value.quantity <= 0) delete filtered[key] });
    setShoppingCart(filtered);
  }

  useEffect(() => {
    onShoppingCartChanged(shoppingCart);
  }, [shoppingCart, onShoppingCartChanged]);

  const columns = [
    {
      id: 'no',
      label: t('common:number'),
      width: '5em',
    },
    {
      id: 'description',
      label: t('common:description'),
    },
    {
      id: 'quantity',
      label: t('common:quantity'),
      width: '20em',
    },
  ];
  return <>
    <SearchField autoFocus value={search} onChange={setSearch} />
    {error && <Warning>{isArray(error) ? t('errorOccured', { message: JSON.stringify(error) }) : error}</Warning>}
    {loading ? <LinearProgress /> : <ProgressPlaceholder />}
    {data && data.items.length === 0 && <p>{t('products.nothingFound')}</p>}
    {data && data.items.length > 0 && <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell style={{ width: column.width }} component="th" scope="row"
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.items.map((row: Product) => {
              return (
                <TableRow tabIndex={-1} key={row.id}>
                  {columns.map(column => {
                    switch (column.id) {
                      case 'description':
                        return <TableCell key={column.id}>
                          <TextWithSubtext subtext={row.description2}>{row.description}</TextWithSubtext>
                        </TableCell>;
                      case 'quantity':
                        return <TableCell key={column.id}>
                          <BuyButton onQuantityChanged={(quantity => onQuantityChanged(row, quantity))} quantity={shoppingCart[row.no] ? shoppingCart[row.no].quantity : 0} />
                        </TableCell>;
                      default:
                        const value = (row as any)[column.id];
                        return <TableCell key={column.id}>
                          {value}
                        </TableCell>
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /></>}
  </>;
}

const mapStateToProps = (state: any) => ({
  error: getError(state),
  data: getData(state),
  loading: isLoading(state),
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  search: getSearch(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  fetchData: () => dispatch(fetchData()),
  setPage: (page: number) => dispatch(setPage(page)),
  setSearch: (search: string) => dispatch(setSearchTerm(search)),
  setRowsPerPage: (rowsPerPage: number) => dispatch(setRowsPerPage(rowsPerPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelector);

import 'whatwg-fetch';

import { BranchOffice, OptionFieldValues, Order, Product } from '../types';
import { setApplicationError, setCurrentUser } from '../state/general/actions';

import { forEach } from 'lodash';
import { getPathName } from '../state/general/selectors';
import { push } from 'connected-react-router';
import { reset } from '../state/orders/actions';
import { store } from '../core/Base';

const localHost = 'http://localhost:3001';
const remoteHost = 'https://webportal-backend.connextis.de';


let accessToken = window.localStorage.getItem('access-token');

const getHost = () => window.location.host === 'localhost:3000' ? localHost : remoteHost;

export interface PagingResult<t> {
  items: t[],
  itemCount: number,
  total: number,
  pageCount: number,
}

export const isSignedIn = () => {
  if (accessToken == null) {
    return Promise.resolve(false);
  } else {
    return fetch(`${getHost()}/auth/isSignedIn`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    })
      .then(response => {
        if (response.ok)
          return response.json();
        else if (response.status === 401)
          return false;

        throw new Error(`${response.status} ${response.statusText}`);
      })
      .catch(error => {
        throw new Error(`Backend-Service kann unter der Adresse ${getHost()} nicht erreicht werden: ${error.message}`);
      });
  }
}

export const signIn = (username: string, password: string) =>
  fetch(`${getHost()}/auth/signin`, {
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
    }),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(response => {
      if (response.ok)
        return response.json();

      return response.json().then(json => { throw json });
    })
    .then(json => {
      accessToken = json.accessToken;
      window.localStorage.setItem('access-token', json.accessToken);
      return json.user;
    });


export const signOut = () => {
  window.localStorage.removeItem('access-token');
  accessToken = null;
  store.dispatch(setCurrentUser(null));
  store.dispatch(reset());
  // Redirect user to sign in page
  const path = getPathName(store.getState());
  if (path !== '/signIn')
    store.dispatch(push('/signIn'));
  return;
}

export const checkLoginState = () => {
  const path = getPathName(store.getState());
  isSignedIn()
    .then(user => {
      if (user !== false)
        store.dispatch(setCurrentUser(user));
      else {
        signOut();

        // Redirect user to sign in page
        if (path !== '/signIn')
          store.dispatch(push('/signIn'));
      }
    })
    .catch(err => {
      store.dispatch(setApplicationError(err.message));
      // Redirect user to sign in page
      if (path !== '/signIn')
        store.dispatch(push('/signIn'));
    })
}

export const getOrders = (page: number, itemsPerPage: number): Promise<PagingResult<any>> =>
  fetch(`${getHost()}/orders/?page=${page}&itemsPerPage=${itemsPerPage}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(response => {
      if (response.ok)
        return response.json();

      return response.json().then(json => { throw json });
    });

export const getOrderById = (id: number): Promise<Order> =>
  fetch(`${getHost()}/orders/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(response => {
      if (response.ok)
        return response.json();

      return response.json().then(json => { throw json });
    });

export const getBranchOffices = (searchTerm: string, page: number, itemsPerPage: number): Promise<PagingResult<BranchOffice>> =>
  fetch(`${getHost()}/branchOffices`, {
    method: 'POST',
    body: JSON.stringify({
      search: searchTerm.trim() !== '' ? searchTerm : null,
      page,
      itemsPerPage
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (response.ok)
        return response.json();

      return response.json().then(json => { throw json });
    });

export const getBranchOfficeById = (id: number): Promise<BranchOffice> =>
  fetch(`${getHost()}/branchOffices/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(response => {
      if (response.ok)
        return response.json();

      return response.json().then(json => { throw json });
    });

export const getProducts = (searchTerm: string, page: number, itemsPerPage: number): Promise<PagingResult<Product>> =>
  fetch(`${getHost()}/products`, {
    method: 'POST',
    body: JSON.stringify({
      search: searchTerm.trim() !== '' ? searchTerm : null,
      page,
      itemsPerPage
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (response.ok)
        return response.json();

      return response.json().then(json => { throw json });
    });

export const saveOrder = (orderData: any, editId?: number): Promise<Order> => {
  // since lodash map or forEach methods on collections doesn't return an index, we do it the old way...
  const positions: any = [];
  let lineNo = 1;
  forEach(orderData.shoppingCart, (product: Product) => {
    positions.push({
      productId: product.id,
      lineNo: lineNo,
      no: product.no,
      description: product.description,
      description2: product.description2,
      quantity: product.quantity,
    });
    lineNo++;
  });

  return fetch(`${getHost()}/orders${editId == null ? '' : `/${editId}`}`, {
    method: editId == null ? 'POST' : 'PATCH',
    body: JSON.stringify(
      {
        branchOfficeId: orderData.branchOffice.id,
        comment: orderData.comment,
        relocationType: orderData.relocationType,
        requestedDeliveryDate: orderData.requestedDeliveryDate,
        newInstallation: (orderData.newInstallation === true),
        remoteInstallation: (orderData.remoteInstallation === true),
        offerRequired: (orderData.offerRequired === true),
        positions: positions,
        deviatingDeliveryAddress: orderData.deviatingDeliveryAddress,
      }
    ),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (response.ok)
        return response.json();

      return response.json().then(json => { throw json });
    });
}

export const getOptionFieldValues = (fieldName: string): Promise<OptionFieldValues> =>
  fetch(`${getHost()}/orders/fields/${fieldName}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(response => {
      if (response.ok)
        return response.json();

      return response.json().then(json => { throw json });
    });

export const fetchReceipt = (orderId: number, receiptId: string): Promise<Blob> =>
  fetch(`${getHost()}/orders/${orderId}/receipt/${receiptId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(response => response.blob());

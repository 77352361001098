import { Button, TextField } from '@material-ui/core';
import React, { FunctionComponent, useRef, useState } from 'react';
import { clamp, debounce } from 'lodash';

import AddIcon from '@material-ui/icons/Add';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  container: {
    '& .Mui-focused, & .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(0, 0, 0, 0.23) !important',
    },
    '& button': {
      height: '3em',
      padding: 0,
    },
    '& .MuiTextField-root': {
      height: '3em',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      width: '4em',
      height: '100%',
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
    },
  },
});

interface BuyButtonProps {
  quantity: number;
  onQuantityChanged: (quantity: number) => void;
}

export const BuyButton: FunctionComponent<BuyButtonProps> = ({ quantity, onQuantityChanged }) => {
  const [fieldFocus, setFieldFocus] = useState(false);
  const classes = useStyles();

  // if the text field lost focus we wait for a second, since user may focus it again or press the + button.
  // if we do not do this with debounce, the buttons would disappear if the user enters 0 and presses the + button.
  const delayedBlur = useRef(debounce(() => setFieldFocus(false), 1000)).current;

  const handleFocus = () => {
    delayedBlur.cancel();
    setFieldFocus(true);
  }

  const handleBlur = () => delayedBlur();

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onQuantityChanged(parseInt(event.target.value) || 0)

  return <div className={classes.container}>
    {!fieldFocus && quantity === 0
      ? <Button variant="contained" color="primary" onClick={() => onQuantityChanged(clamp(quantity + 1, 0, Infinity))}><AddShoppingCartIcon /></Button>
      : <div>
        <Button variant="outlined" onClick={() => onQuantityChanged(clamp(quantity - 1, 0, Infinity))}>{quantity === 1 ? <DeleteIcon /> : <RemoveIcon />}</Button>
        <TextField variant="outlined" value={quantity} onChange={handleTextFieldChange} type="number" className="numberAsTextField" onFocus={handleFocus} onBlur={handleBlur} />
        <Button variant="contained" color="secondary" onClick={() => onQuantityChanged(clamp(quantity + 1, 0, Infinity))}><AddIcon /></Button>
      </div>
    }
  </div>
}
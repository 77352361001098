import * as actionTypes from './actionTypes';

import { getOptionFieldValues } from '../../services/BackendService';
import { getOptionFields } from './selectors';

export const setCurrentUser = (user: any) => ({
  type: actionTypes.SET_CURRENT_USER,
  payload: user,
});

export const setApplicationError = (error: string | null) => ({
  type: actionTypes.SET_APPLICATION_ERROR,
  payload: error,
});

export const setDetailEntry = (entryId: number | null) => ({
  type: actionTypes.SET_DETAIL_ENTRY,
  payload: entryId,
});

let isFetchingOptionFields = false;
export const fetchOptionFieldValues = () => (dispatch: any, getState: any) => {
  if (getOptionFields(getState()) != null || isFetchingOptionFields)
    return;

  isFetchingOptionFields = true;
  const promises = [
    getOptionFieldValues('status'),
    getOptionFieldValues('relocationType'),
  ];
  if (getOptionFields(getState()) == null) {
    return Promise.all(promises)
      .then((results) => {
        return dispatch({
          type: actionTypes.SET_OPTION_FIELDS,
          payload: {
            status: results[0],
            relocationType: results[1],
          },
        });
      })
      .finally(() => {
        isFetchingOptionFields = false;
      });
  }
}

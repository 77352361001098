import './App.css';

import { Base, Router } from './core';
import { NewOrderPage, NotFoundPage, OrdersPage, PageWrapper, SignInPage } from './pages';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';

import ApplicationError from './components/ApplicationError';
import Header from './components/Header';

const App: React.FC = () =>
  <Base>
    <Suspense fallback={null}>
      <Router>
        <Header />
        <PageWrapper>
          <ApplicationError />
          <Switch>
            <Route exact path="/signin" render={() => <SignInPage />} />
            <Route path="/orders/new" render={(props) => <NewOrderPage />} />
            <Route path="/orders/edit/:id" render={(props) => <NewOrderPage editId={parseInt(props.match.params.id)} />} />
            <Route path="/" render={() => <OrdersPage />} />
            <Route render={() => <NotFoundPage />} />
          </Switch>
        </PageWrapper>
      </Router>
    </Suspense>
  </Base>

export default App;

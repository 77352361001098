import React, { FunctionComponent, useState } from 'react';

import { Action } from 'redux';
import { Button } from '@material-ui/core';
import { PageHeader } from '../../components/PageHeader';
import ProductSelector from '../../components/ProductSelector';
import { StepButtonRow } from '../../components/styled';
import { StepProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { getOrderData } from '../../state/orders/selectors';
import { setOrderData } from '../../state/orders/actions';
import { useTranslation } from 'react-i18next';

const Step3: FunctionComponent<StepProps> = ({
  onStepCompleted,
  orderData,
  setOrderData,
  onBackClick,
}) => {
  const { t } = useTranslation();
  const [cart, setCart] = useState(orderData.shoppingCart || {});

  const complete = () => {
    setOrderData(cart);
    onStepCompleted();
  }

  const onShoppingCardChanged = (cart: any) => setCart(cart);

  const isValid = Object.keys(cart).length > 0;

  return <>
    <PageHeader dense>{t('order.selectProducts')}</PageHeader>
    <StepButtonRow>
      <Button variant="contained" color="default" onClick={onBackClick ? () => onBackClick() : undefined}>{t('common:back')}</Button>
      <Button variant="contained" color="primary" onClick={complete} disabled={!isValid}>{t('common:next')}</Button>
    </StepButtonRow>
    <ProductSelector onShoppingCartChanged={onShoppingCardChanged} initialShoppingCart={cart} />
  </>
}

const mapStateToProps = (state: any) => ({
  orderData: getOrderData(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  setOrderData: (shoppingCart: any) => dispatch(setOrderData({ shoppingCart })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step3);

import React, { FunctionComponent } from 'react';
import { Theme, fade } from '@material-ui/core/styles';

import { InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '10em',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

interface SearchFieldProps {
  autoFocus: boolean;
  value: string;
  onChange: Function;
}

export const SearchField: FunctionComponent<SearchFieldProps> = ({ autoFocus, value, onChange }) => {
  const classes = useStyles();
  return <div className={classes.search}>
    <div className={classes.searchIcon}>
      <SearchIcon />
    </div>
    <InputBase
      placeholder="Suche…"
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
      }}
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
      autoFocus={autoFocus}
    />
  </div>
};

import { BranchOffice, OptionFieldValues, Position, PostAddress, Product, Receipt } from '../../types';
import { Button, Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import { BranchOfficeCard } from '../BranchOfficeCard';
import { Moment } from 'moment';
import { OrderPositionsList } from '../OrderPositionsList';
import ReceiptIcon from '@material-ui/icons/Description';
import { fetchReceipt } from '../../services/BackendService';
import { getOptionFieldLanguageKey } from '../../utils';
import { useTranslation } from 'react-i18next';

interface OrderDetailsProps {
  id?: number;
  comment?: string;
  relocationType: number;
  requestedDeliveryDate: Moment;
  newInstallation: boolean;
  remoteInstallation: boolean;
  offerRequired: boolean;
  branchOffice: BranchOffice;
  shoppingCart?: {
    string: Product;
  };
  positions?: Position[];
  deviatingDeliveryAddress?: PostAddress;
  optionFields?: any;
  receipts?: Receipt[];
}

export const OrderDetails: FunctionComponent<OrderDetailsProps> = ({
  id,
  comment,
  relocationType,
  requestedDeliveryDate,
  newInstallation,
  remoteInstallation,
  offerRequired,
  branchOffice,
  shoppingCart,
  positions,
  deviatingDeliveryAddress,
  optionFields,
  receipts,
}) => {
  const { t, i18n } = useTranslation();

  const downloadReceipt = (receipt: Receipt) => {
    if (!id)
      return;

    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    fetchReceipt(id, receipt.id)
      .then(blob => {
        let objectUrl = window.URL.createObjectURL(blob);

        anchor.href = objectUrl;
        anchor.download = receipt.filename;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      })
  }

  return <Grid container justify="center" spacing={2} alignItems="stretch">
    <Grid item xs={12} sm={6} md={8}>
      <Typography variant="h6" gutterBottom>{t('common:branchOffice')}</Typography>
      <BranchOfficeCard branchOffice={branchOffice} deviatingDeliveryAddress={deviatingDeliveryAddress} fullWidth />
      <div className="flex space-around">
        <Typography variant="subtitle1" component="span">{t('common:newInstallation')}: {newInstallation ? t('common:yes') : t('common:no')}</Typography>
        <Typography variant="subtitle1" component="span">{t('common:remoteInstallation')}: {remoteInstallation ? t('common:yes') : t('common:no')}</Typography>
        <Typography variant="subtitle1" component="span">{t('common:offerRequired')}: {offerRequired ? t('common:yes') : t('common:no')}</Typography>
      </div>
    </Grid>
    <Grid item xs={12} sm={6} md={4} className="vertical-stretched align-right">
      <div>
        <Typography variant="h6" gutterBottom>{t('common:requestedDeliveryDate')}</Typography>
        {requestedDeliveryDate.format('LL')}
      </div>
      <div>
        <Typography variant="h6" gutterBottom>{t('common:relocationType')}</Typography>
        {optionFields != null && relocationType != null ? (optionFields.relocationType as OptionFieldValues)[relocationType][getOptionFieldLanguageKey(i18n.language)] : '-'}
      </div>
      <div>
        <Typography variant="h6" gutterBottom>{t('common:comment')}</Typography>
        {comment
          ? <p className="nomargin">{comment}</p>
          : <p className="nomargin italic">{t('order.nocomment')}</p>
        }
      </div>
    </Grid>
    {receipts && receipts.length > 0 && <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>{t('common:receipts')}</Typography>
      {receipts.map(receipt => <Button className="small-margin" size="small" variant="outlined" color="secondary" startIcon={<ReceiptIcon />} onClick={() => downloadReceipt(receipt)}>{receipt.filename}</Button>)}
    </Grid>}
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>{t('common:selectedProducts')}</Typography>
      <OrderPositionsList positions={positions ? positions : shoppingCart} />
    </Grid>
  </Grid>
}
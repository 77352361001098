import React, { FunctionComponent } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { Product } from '../../types';
import { TextWithSubtext } from '../TextWithSubtext';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

interface OrderPositionsListProps {
  positions: any;
}

export const OrderPositionsList: FunctionComponent<OrderPositionsListProps> = ({ positions }) => {
  const { t } = useTranslation();

  const columns = [
    {
      id: 'lineNo',
      label: t('common:lineNo'),
      width: '5em',
    },
    {
      id: 'no',
      label: t('common:number'),
      width: '5em',
    },
    {
      id: 'description',
      label: t('common:description'),
    },
    {
      id: 'quantity',
      label: t('common:quantity'),
      width: '20em',
    },
  ];
  let i = 0;
  return <>
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell style={{ width: column.width }} component="th" scope="row"
                key={column.id}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(positions, (row: Product) => {
            i++;
            return (
              <TableRow tabIndex={-1} key={row.id}>
                {columns.map(column => {
                  const value = (row as any)[column.id];
                  switch (column.id) {
                    case 'description':
                      return <TableCell key={column.id}>
                        <TextWithSubtext subtext={row.description2}>{row.description}</TextWithSubtext>
                      </TableCell>;
                    case 'lineNo':
                      return <TableCell key={column.id}>
                        {value || i}
                      </TableCell>
                    default:
                      return <TableCell key={column.id}>
                        {value}
                      </TableCell>
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

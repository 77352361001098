import { Button, Container, IconButton } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useRef } from 'react';

import { Action } from 'redux';
import { Breadcrumb } from '../Breadcrumb';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IdleTimer from 'react-idle-timer';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchOptionFieldValues } from '../../state/general/actions';
import { getCurrentUser } from '../../state/general/selectors';
import { makeStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import { signOut } from '../../services/BackendService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    fontFamily: '"Sofia Pro Condensed", "Calibri", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: 300,
    backgroundColor: '#f4f4f4',
    height: '40px',
    fontSize: '14px',
    '& > .MuiContainer-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    '& p': {
      margin: 0,
    },
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& > p': {
      marginRight: '1em',
    },
    '& .MuiIconButton-root': {
      borderRadius: 0,
      height: '100%',
    },
  },
});

const logout = () => signOut();

interface HeaderProps {
  currentUser: any;
  fetchOptionFields: Function;
  push: Function;
}
const Header: FunctionComponent<HeaderProps> = ({ currentUser, fetchOptionFields, push }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const idleTimer = useRef<any>();

  useEffect(() => {
    fetchOptionFields();
  }, [fetchOptionFields, currentUser])

  return <div className={classes.root}>
    <Container>
      <IdleTimer ref={ref => { idleTimer.current = ref }} onIdle={logout} timeout={5*60*1000} />
      <Breadcrumb onClick={() => push('/')} />
      <div className={classes.rightSection}>
        {currentUser
          ? <><p>{t('translation:loggedInAs', { username: currentUser.username })}</p><IconButton aria-label={t('common:signOut')} title={t('common:signOut')} color="inherit" onClick={logout}><ExitToAppIcon /></IconButton></>
          : <Button color="inherit" onClick={() => push('/signIn')}>{t('common:signIn')}</Button>
        }
      </div>
    </Container>
  </div>
}

const mapStateToProps = (state: any) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  fetchOptionFields: () => dispatch(fetchOptionFieldValues()),
  push: (path: string) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

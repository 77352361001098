import React, { FunctionComponent } from 'react';

import { Error } from '../styled';
import { connect } from 'react-redux';
import { getApplicationError } from '../../state/general/selectors';

interface Props {
  error: string | null;
}

const ApplicationError: FunctionComponent<Props> = ({ error }) =>
  error ? <Error>{error}</Error> : null

const mapStateToProps = (state: any) => ({
  error: getApplicationError(state),
});

export default connect(mapStateToProps)(ApplicationError);
import { Container, Theme } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '4em',
  },
}));

export const PageWrapper: FunctionComponent = ({ children }) => {
  const classes = useStyles();
  return <Container className={classes.root}>
    {children}
  </Container>;
};

export { NotFoundPage } from './NotFoundPage';
export { default as NewOrderPage } from './NewOrderPage';
export { default as SignInPage } from './SignInPage';
export { default as OrdersPage } from './OrdersPage';
//export { default as DetailPage } from './DetailPage';

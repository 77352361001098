import * as actionTypes from './actionTypes';

import { getPage, getRowsPerPage, getSearch, isLoading } from './selectors';

import { debounce } from 'lodash';
import { getBranchOffices } from '../../services/BackendService';

export const reset = () => ({
  type: actionTypes.RESET,
});

export const setPage = (page: number) => (dispatch: any, getState: any) => {
  dispatch({
    type: actionTypes.SET_PAGE,
    payload: page,
  });

  dispatch(fetchData());
};

export const setRowsPerPage = (rowsPerPage: number) => (dispatch: any, getState: any) => {
  dispatch({
    type: actionTypes.SET_ROWS_PER_PAGE,
    payload: rowsPerPage,
  });

  dispatch(fetchData());
};

const debouncedPerformSearch = debounce((dispatch, getState) => {
  dispatch(performSearch());
}, 500);

export const setSearchTerm = (text: string) => (dispatch: any, getState: any) => {
  dispatch({
    type: actionTypes.SET_SEARCH,
    payload: text,
  });

  if (text.trim().length  >= 1 && text.trim().length < 3) {
    debouncedPerformSearch.cancel();
  } else {
    debouncedPerformSearch(dispatch, getState);
  }
};

export const performSearch = () => (dispatch: any, getState: any) => {
  dispatch(setPage(0));
  dispatch(fetchData());
}

export const fetchData = () => (dispatch: any, getState: any) => {
  const state = getState();

  if (isLoading(state))
    return;

  dispatch({
    type: actionTypes.FETCH_DATA_REQUEST,
  });

  return getBranchOffices(getSearch(state), getPage(state), getRowsPerPage(state))
    .then(result => {
      return dispatch({
        type: actionTypes.FETCH_DATA_SUCCESS,
        payload: result,
      });
    })
    .catch((error: any) => dispatch({
      type: actionTypes.FETCH_DATA_FAILURE,
      payload: error.message,
    }));
}


import React, { FunctionComponent, useState } from 'react';
import { setCurrentUser } from '../../state/general/actions';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../state/general/selectors';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Typography, TextField, Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { signIn } from '../../services/BackendService';
import { push } from 'connected-react-router';
import { useMount } from 'react-use';
import { checkLoginState } from '../../services/BackendService';
import { useTranslation } from 'react-i18next';
import { Error } from '../../components/styled';
import { PageHeader } from '../../components/PageHeader';

const Container = styled('div')({
  width: '40em',
  maxWidth: '90%',
  margin: '0 auto',
});

const Form = styled('form')({
  display: 'flex',
  flexFlow: 'column',

  '& button': {
    marginTop: '2em',
  },
});

interface Props {
  currentUser: any;
  setCurrentUser: Function;
  push: Function;
}

const SignInPage: FunctionComponent<Props> = ({ currentUser, setCurrentUser, push }) => {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();

  const onFormSubmit = (event: any) => {
    setError(null);
    event.preventDefault();

    signIn(username, password)
      .then((user: any) => {
        setCurrentUser(user);
        push('/');
      })
      .catch(e => setError(e.message.toString()));
  }

  useMount(() => checkLoginState());

  return <Container>
    <PageHeader>{t('common:signIn')}</PageHeader>
    {currentUser
      ? <Typography variant="body1" gutterBottom>Sie sind als <b>{currentUser.username}</b> eingeloggt!</Typography>
      : <Form>
        <TextField
          error={error ? true : false}
          label={t('common:username')}
          type="text"
          margin="normal"
          value={username}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
        />
        <TextField
          error={error ? true : false}
          label={t('common:password')}
          type="password"
          margin="normal"
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" onClick={onFormSubmit} disabled={username.trim() === '' || password.trim() === ''}>{t('common:submit')}</Button>
        {error && <Error>{error}</Error>}
      </Form>
    }
  </Container>;
}

const mapStateToProps = (state: any) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  setCurrentUser: (obj: any) => dispatch(setCurrentUser(obj)),
  push: (path: string) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
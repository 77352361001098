import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { OptionFieldValues, Order } from '../../types';
import React, { FunctionComponent, useState } from 'react';

import { Action } from 'redux';
import { OrderDetails } from '../../components/OrderDetails';
import OrdersList from '../../components/OrdersList';
import { PageHeader } from '../../components/PageHeader';
import { ThunkDispatch } from 'redux-thunk';
import { checkLoginState } from '../../services/BackendService';
import { connect } from 'react-redux';
import { formatDate } from '../../helpers/utils';
import { getOptionFieldLanguageKey } from '../../utils';
import { getOptionFields } from '../../state/general/selectors';
import moment from 'moment';
import { push } from 'connected-react-router';
import { setEditOrderData } from '../../state/orders/actions';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';

interface OrdersPageProps {
  push: Function;
  optionFields: any;
  setEditOrderData: Function;
}

const OrdersPage: FunctionComponent<OrdersPageProps> = ({ push, optionFields, setEditOrderData }) => {
  const { t, i18n } = useTranslation();
  const [orderDetails, setOrderDetails] = useState<Order | null>(null);
  useMount(() => checkLoginState());

  const onNewOrderClick = () => push('/orders/new');
  const onOrderClick = (order: Order) => setOrderDetails(order);

  const closeDialog = () => setOrderDetails(null);
  const editOrder = () => {
    setEditOrderData(orderDetails!.id).then(() => {
      push(`/orders/edit/${orderDetails!.id}`);
      closeDialog();
    });
  }

  return <>
    <PageHeader
      button={<Button type="submit" variant="contained" color="primary" onClick={onNewOrderClick}>{t('common:newOrder')}</Button>}
    >{t('orders')}</PageHeader>
    <OrdersList optionFields={optionFields} onRowClick={onOrderClick} />
    {orderDetails && <Dialog open={true} onClose={closeDialog} fullWidth maxWidth="lg">
      <DialogTitle className="horizontal-stretched" disableTypography>
        <div>
          <Typography variant="h6" component="h2">{t('order.details')}</Typography>
          <Typography variant="caption">
            {orderDetails.user
              ? t('order.createdByUser', { username: orderDetails.user.username, date: formatDate(orderDetails.createdDate) })
              : t('order.created', { date: formatDate(orderDetails.createdDate) })
            }
            {orderDetails.modifiedDate && <>, {t('order.modified', { date: formatDate(orderDetails.createdDate) })}</>}
          </Typography>
        </div>
        {optionFields && orderDetails.status != null && <Chip color="secondary" label={(optionFields.status as OptionFieldValues)[orderDetails.status][getOptionFieldLanguageKey(i18n.language)]} />}
      </DialogTitle>
      <DialogContent>
        <OrderDetails
          id={orderDetails.id}
          branchOffice={orderDetails.branchOffice}
          requestedDeliveryDate={moment(orderDetails.requestedDeliveryDate)}
          relocationType={orderDetails.relocationType}
          comment={orderDetails.comment}
          positions={orderDetails.positions}
          newInstallation={orderDetails.newInstallation}
          remoteInstallation={orderDetails.remoteInstallation}
          offerRequired={orderDetails.offerRequired}
          deviatingDeliveryAddress={orderDetails.deviatingDeliveryAddress}
          receipts={orderDetails.receipts}
          optionFields={optionFields}
        />
      </DialogContent>
      <DialogActions>
        {orderDetails.status === 0 && <Button onClick={editOrder} color="secondary">{t('common:edit')}</Button>}
        <Button onClick={closeDialog} color="primary" autoFocus>{t('common:close')}</Button>
      </DialogActions>
    </Dialog>}
  </>;
}

const mapStateToProps = (state: any) => ({
  optionFields: getOptionFields(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  push: (path: string) => dispatch(push(path)),
  setEditOrderData: (orderId: number) => dispatch(setEditOrderData(orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);
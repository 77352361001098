import { Chip, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import { OptionFieldValues, Order } from '../../types';
import React, { FunctionComponent } from 'react';
import { fetchData, setPage, setRowsPerPage } from '../../state/orders/actions';
import { getData, getError, getPage, getRowsPerPage, isLoading } from '../../state/orders/selectors';

import { Action } from 'redux';
import { BranchOfficeCard } from '../BranchOfficeCard';
import { PagingResult } from '../../services/BackendService';
import { ThunkDispatch } from 'redux-thunk';
import { Warning } from '../styled';
import { connect } from 'react-redux';
import { formatDate } from '../../helpers/utils';
import { getOptionFieldLanguageKey } from '../../utils';
import { isArray } from 'lodash';
import moment from 'moment';
import { push } from 'connected-react-router';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';

interface OrdersListProps {
  fetchData: Function;
  error: any;
  data: PagingResult<any>;
  loading: boolean;
  rowsPerPage: number;
  page: number;
  setPage: Function;
  setRowsPerPage: Function;
  onRowClick: (order: Order) => void;
  optionFields?: any;
}

const OrdersList: FunctionComponent<OrdersListProps> = ({
  error,
  data,
  loading,
  fetchData,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  onRowClick,
  optionFields,
}) => {
  const { t, i18n } = useTranslation();
  useMount(() => fetchData());

  const handleChangePage = (event: any, page: number) => setPage(page);
  const handleChangeRowsPerPage = (event: any) => setRowsPerPage(event.target.value);

  const columns = [
    {
      id: 'branchOffice',
      label: t('common:branchOffice'),
    },
    {
      id: 'requestedDeliveryDate',
      label: t('common:requestedDeliveryDate'),
      width: '13em',
    },
    {
      id: 'comment',
      label: t('common:comment'),
      width: '15em',
    },
    {
      id: 'positions',
      label: t('common:positions'),
      width: '5em',
    },
    {
      id: 'receipts',
      label: t('common:receipts'),
      width: '3em',
    },
    {
      id: 'status',
      label: t('common:status'),
      width: '8em',
    },
    {
      id: 'createdDate',
      label: t('common:createdDate'),
      width: '13em',
    },
  ];

  return <>
    {error && <Warning>{isArray(error) ? t('errorOccured', { message: JSON.stringify(error) }) : error}</Warning>}
    {loading && <LinearProgress />}
    {data && data.items.length === 0 && <p>{t('noOrders')}</p>}
    {data && data.items.length > 0 && <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell style={{ width: column.width }} component="th" scope="row"
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.items.map((row: Order) => {
              return (
                <TableRow className="clickable" hover role="checkbox" tabIndex={-1} key={row.id} onClick={() => onRowClick(row)}>
                  {columns.map(column => {
                    const value = (row as any)[column.id];
                    switch (column.id) {
                      case 'branchOffice':
                        return <TableCell key={column.id}>
                          <BranchOfficeCard branchOffice={value} fullWidth simple />
                        </TableCell>;
                      case 'requestedDeliveryDate':
                        return <TableCell key={column.id}>
                          {moment(value).format('L')}
                        </TableCell>
                      case 'createdDate':
                        return <TableCell key={column.id}>
                          {formatDate(row.createdDate)}
                          {row.user && <><br />von <b>{row.user.username}</b></>}
                          {row.modifiedDate && <Typography variant="caption" display="block">{t('order.modified', { date: formatDate(row.modifiedDate) })}</Typography>}
                        </TableCell>
                      case 'positions':
                        return <TableCell key={column.id}>
                          {row.positions.length}
                        </TableCell>
                      case 'status':
                        return <TableCell key={column.id}>
                          {optionFields && row.status != null && <Chip color="secondary" label={(optionFields.status as OptionFieldValues)[row.status][getOptionFieldLanguageKey(i18n.language)]} />}
                        </TableCell>;
                      case 'receipts':
                        return <TableCell key={column.id}>
                          {row.receipts ? row.receipts.length : 0}
                        </TableCell>
                      default:
                        return <TableCell key={column.id}>
                          {value}
                        </TableCell>
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /></>}
  </>;
}

const mapStateToProps = (state: any) => ({
  error: getError(state),
  data: getData(state),
  loading: isLoading(state),
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  fetchData: (syncId: number) => dispatch(fetchData()),
  setPage: (page: number) => dispatch(setPage(page)),
  setRowsPerPage: (rowsPerPage: number) => dispatch(setRowsPerPage(rowsPerPage)),
  push: (path: string) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);
import '../utils/i18n';

import React, { FunctionComponent } from 'react';
import { deDE, enUS } from '@material-ui/core/locale';

import { CORPORATE_COLORS } from '../constants';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import configureStore from '../state/Store';
import { createMuiTheme } from '@material-ui/core/styles';
import { getOptionFieldLanguageKey } from '../utils';
import i18n from 'i18next';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: CORPORATE_COLORS.RED,
    },
    secondary: {
      main: CORPORATE_COLORS.BLUE,
    },
  },
}, getOptionFieldLanguageKey(i18n.language) === 'de' ? deDE : enUS);

export const store = configureStore({});

export const Base: FunctionComponent = ({ children }) => {
  return <Provider store={store}>
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  </Provider>
}


import 'moment/locale/de';
import 'moment/locale/en-gb';

import { de_common, de_messages, de_translation, en_common, en_messages, en_translation } from '../locales/';

import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

const resources = {
  en: {
    common: en_common,
    messages: en_messages,
    translation: en_translation,
  },
  de: {
    common: de_common,
    messages: de_messages,
    translation: de_translation,
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ['querystring', 'navigator'],
    },
    resources: resources,
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
  })
  .then((x) => {
    // set the moment default locale based on i18n locale
    moment.locale(i18n.language);
  });

export default i18n;

import * as actionTypes from './actionTypes';

const initialState: {
    loading: boolean;
    error: any;
    data: any;
    rowsPerPage: number;
    page: number;
    order: any;
} = {
    loading: false,
    error: null,
    data: null,
    rowsPerPage: 25,
    page: 0,
    order: {},
};

export default function reducer(state = initialState, action: any) {
    const { payload } = action;

    switch (action.type) {
        case actionTypes.RESET:
            return { ...initialState };

        case actionTypes.SET_ORDER_DATA:
            return {
                ...state,
                order: {
                    ...state.order,
                    ...payload,
                }
            };

        case actionTypes.SET_PAGE:
            return {
                ...state,
                page: payload,
            }

        case actionTypes.SET_ROWS_PER_PAGE:
            return {
                ...state,
                rowsPerPage: payload,
                page: 0,
            }

        case actionTypes.FETCH_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case actionTypes.FETCH_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        case actionTypes.FETCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
            };

        default:
            return state;

    }
}

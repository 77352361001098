import React, { FunctionComponent, ReactNode } from 'react';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

interface PageHeaderProps {
  children: string;
  button?: ReactNode;
  dense?: boolean;
}

const useStyles = makeStyles({
  container: {
    '& > h4': {
      marginBottom: '0',
    },
    marginBottom: '2em',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dense: {
    marginBottom: '1em',
  }
});


export const PageHeader: FunctionComponent<PageHeaderProps> = ({ children, button, dense }) => {
  const classes = useStyles();
  const h4 = <Typography variant="h4">{children}</Typography>;

  return <div className={clsx(classes.container, { [classes.flex]: button != null, [classes.dense]: dense })}>
    {h4}
    {button}
  </div>
};

import React, { FunctionComponent } from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import FaxIcon from '@material-ui/icons/Print';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles({
  icons: {
    '& > svg': {
      fontSize: '0.8rem',
    },
  },
  name: {
    marginTop: 0,
    marginBottom: '0.5em',
    fontWeight: 'bold',
  },
});

interface ContactProps {
  contact?: string;
  mail?: string;
  phone?: string;
  fax?: string;
}

export const Contact: FunctionComponent<ContactProps> = ({ contact, mail, phone, fax }) => {
  const classes = useStyles();

  // Prevent office selection events when this component is used in clickable table wors
  const onClick = (event: any) => {
    event.stopPropagation();
    return false;
  };

  return (
    <>
    {contact && <p className={classes.name}>{contact}</p>}
    <Typography className={classes.icons} variant="body2" component="p">
      {mail && <><MailIcon /> <a onClick={onClick} href={`mailto:${mail}`}>{mail}</a><br /></>}
      {phone && <><PhoneIcon /> <a onClick={onClick} href={`tel:${phone}`}>{phone}</a><br /></>}
      {fax && <><FaxIcon /> {fax}<br /></>}
    </Typography>
    </>
  );
}
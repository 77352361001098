import { Button, LinearProgress } from '@material-ui/core';
import { ProgressPlaceholder, StepButtonRow, Warning } from '../../components/styled';
import React, { FunctionComponent, useState } from 'react';

import { Action } from 'redux';
import { OrderDetails } from '../../components/OrderDetails';
import { PageHeader } from '../../components/PageHeader';
import { StepProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { getOptionFields } from '../../state/general/selectors';
import { getOrderData } from '../../state/orders/selectors';
import { isArray } from 'lodash';
import { saveOrder } from '../../services/BackendService';
import { setOrderData } from '../../state/orders/actions';
import { useTranslation } from 'react-i18next';

const Step4: FunctionComponent<StepProps> = ({
  onStepCompleted,
  orderData,
  setOrderData,
  onBackClick,
  optionFields,
  editId,
}) => {
  const isEditing = editId != null;
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<any>(null);

  const submitOrder = () => {
    setSubmitting(true);
    saveOrder(orderData, editId)
      .then(() => onStepCompleted())
      .catch(error => {
        setError(error.message);
        setSubmitting(false);
      });
  }

  return <>
    <PageHeader dense>{t('order.summary')}</PageHeader>
    <StepButtonRow>
      <Button variant="contained" color="default" onClick={onBackClick ? () => onBackClick() : undefined} disabled={submitting}>{t('common:back')}</Button>
      <Button variant="contained" color="primary" onClick={submitOrder} disabled={submitting}>{isEditing ? t('common:saveChanges') : t('common:submitOrder')}</Button>
    </StepButtonRow>
    {submitting ? <LinearProgress /> : <ProgressPlaceholder />}
    {error && <Warning>{isArray(error) ? t('errorOccured', { message: JSON.stringify(error) }) : error}</Warning>}
    <OrderDetails
      branchOffice={orderData.branchOffice}
      comment={orderData.comment}
      relocationType={orderData.relocationType}
      requestedDeliveryDate={orderData.requestedDeliveryDate}
      newInstallation={orderData.newInstallation}
      remoteInstallation={orderData.remoteInstallation}
      offerRequired={orderData.offerRequired}
      shoppingCart={orderData.shoppingCart}
      deviatingDeliveryAddress={orderData.deviatingDeliveryAddress}
      optionFields={optionFields}
    />
  </>
}

const mapStateToProps = (state: any) => ({
  orderData: getOrderData(state),
  optionFields: getOptionFields(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  setOrderData: (shoppingCart: any) => dispatch(setOrderData({ shoppingCart })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step4);

import { Card, CardContent, TextField, makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { PostAddress } from '../../types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  card: {
    maxWidth: '18em',
    '& h6': {
      fontSize: '1.1rem',
    },
    margin: '0.5em',
    display: 'flex',
  },
  content: {
    padding: '0.5em 1em !important',
    flexGrow: 1,
  },
  fullWidth: {
    maxWidth: 'none',
  },
  zip: {
    width: '5em',
    marginRight: '0.5em',
  },
  countryCode: {
    width: '5em',
  }
});

interface AddressFormProps {
  fullWidth?: boolean;
  className?: string;
  address?: PostAddress;
  onChange: (address: PostAddress) => void;
}

export const AddressForm: FunctionComponent<AddressFormProps> = ({ className, fullWidth, address, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [address1, setAddress1] = useState<string>(address ? address.address : '');
  const [address2, setAddress2] = useState<string>(address ? address.address2 : '');
  const [postalCode, setPostalCode] = useState<string>(address ? address.postalCode : '');
  const [city, setCity] = useState<string>(address ? address.city : '');
  const [county, setCounty] = useState<string>(address ? address.county || '' : '');
  const [countryCode, setCountryCode] = useState<string>(address ? address.countryCode || '' : '');

  useEffect(() => {
    onChange({
      address: address1,
      address2,
      postalCode,
      city,
      county,
      countryCode
    });
  }, [address1, address2, postalCode, city, county, countryCode, onChange]);


  return (
    <Card className={clsx(classes.card, fullWidth ? classes.fullWidth : null, className)} variant="outlined" square>
      <CardContent className={classes.content}>
        <TextField
          label={t('common:addressFields.address')}
          value={address1}
          onChange={(event: any) => setAddress1(event.target.value)}
          fullWidth
        />
        <TextField
          label={t('common:addressFields.address2')}
          value={address2}
          onChange={(event: any) => setAddress2(event.target.value)}
          fullWidth
        />
        <div className="flex">
          <TextField
            label={t('common:addressFields.postalCode')}
            value={postalCode}
            onChange={(event: any) => setPostalCode(event.target.value)}
            className={classes.zip}
          />
          <TextField
            label={t('common:addressFields.city')}
            value={city}
            onChange={(event: any) => setCity(event.target.value)}
            fullWidth
          />
        </div>
        <TextField
          label={t('common:addressFields.county')}
          value={county}
          onChange={(event: any) => setCounty(event.target.value)}
          fullWidth
        />
        <TextField
          label={t('common:addressFields.countryCode')}
          value={countryCode}
          onChange={(event: any) => setCountryCode(event.target.value.toUpperCase())}
          className={classes.countryCode}
        />
      </CardContent>
    </Card>
  );
}
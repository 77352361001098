import branchOffices from './branchOffices';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import general from './general';
import orders from './orders';
import products from './products';

export default (history) => combineReducers({
    router: connectRouter(history),
    orders: orders.reducer,
    branchOffices: branchOffices.reducer,
    products: products.reducer,
    general: general.reducer,
});

import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { ProgressPlaceholder, Warning } from '../styled';
import React, { FunctionComponent } from 'react';
import { fetchData, setPage, setRowsPerPage, setSearchTerm } from '../../state/branchOffices/actions';
import { getData, getError, getPage, getRowsPerPage, getSearch, isLoading } from '../../state/branchOffices/selectors';

import { Action } from 'redux';
import { Address } from '../Address';
import { BranchOffice } from '../../types';
import { Contact } from '../Contact';
import { PagingResult } from '../../services/BackendService';
import { SearchField } from '../../components/SearchField';
import { TextWithSubtext } from '../TextWithSubtext';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { isArray } from 'lodash';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';

interface BranchOfficePickerProps {
  fetchData: Function;
  error: any;
  data: PagingResult<any>;
  loading: boolean;
  rowsPerPage: number;
  page: number;
  search: string;
  setPage: Function;
  setRowsPerPage: Function;
  setSearch: Function;
  onSelected: (branchOffice: BranchOffice) => void;
}

const BranchOfficePicker: FunctionComponent<BranchOfficePickerProps> = ({
  fetchData,
  error,
  data,
  loading,
  rowsPerPage,
  page,
  search,
  setPage,
  setRowsPerPage,
  setSearch,
  onSelected,
}) => {
  const { t } = useTranslation();
  useMount(() => fetchData());

  const handleChangePage = (event: any, page: number) => setPage(page);
  const handleChangeRowsPerPage = (event: any) => setRowsPerPage(event.target.value);

  const columns = [
    {
      id: 'code',
      label: t('common:code'),
      width: '5em',
    },
    {
      id: 'name',
      label: t('common:name'),
    },
    {
      id: 'address',
      label: t('common:address'),
      width: '20em',
    },
    {
      id: 'contact',
      label: t('common:contact'),
      width: '20em',
    },
  ];
  return <>
    <SearchField autoFocus value={search} onChange={setSearch} />
    {error && <Warning>{isArray(error) ? t('errorOccured', { message: JSON.stringify(error) }) : error}</Warning>}
    {loading ? <LinearProgress /> : <ProgressPlaceholder />}
    {data && data.items.length === 0 && <p>{t('branchOffices.nothingFound')}</p>}
    {data && data.items.length > 0 && <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell style={{ width: column.width }} component="th" scope="row"
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.items.map((row: BranchOffice) => {
              return (
                <TableRow className="clickable" hover tabIndex={-1} key={row.id} onClick={() => onSelected(row)}>
                  {columns.map(column => {
                    switch (column.id) {
                      case 'address':
                        return <TableCell key={column.id}>
                          <Address address={row.address} address2={row.address2} postalCode={row.postalCode} city={row.city} county={row.county} country={row.countryCode} />
                        </TableCell>;
                      case 'contact':
                        return <TableCell key={column.id}>
                          <Contact contact={row.contact} mail={row.mail} phone={row.phone} fax={row.fax} />
                        </TableCell>;
                      case 'name':
                        return <TableCell key={column.id}>
                          <TextWithSubtext subtext={row.name2}>{row.name}</TextWithSubtext>
                        </TableCell>;
                      default:
                        const value = (row as any)[column.id];
                        return <TableCell key={column.id}>
                          {value}
                        </TableCell>
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /></>}
  </>;
}

const mapStateToProps = (state: any) => ({
  error: getError(state),
  data: getData(state),
  loading: isLoading(state),
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  search: getSearch(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  fetchData: () => dispatch(fetchData()),
  setPage: (page: number) => dispatch(setPage(page)),
  setSearch: (search: string) => dispatch(setSearchTerm(search)),
  setRowsPerPage: (rowsPerPage: number) => dispatch(setRowsPerPage(rowsPerPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchOfficePicker);

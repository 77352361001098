import React, { FunctionComponent, useState } from 'react';
import { Step, StepLabel, Stepper, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { reset, setEditOrderData, setOrderData } from '../../state/orders/actions';

import { Action } from 'redux';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import { ThunkDispatch } from 'redux-thunk';
import { checkLoginState } from '../../services/BackendService';
import { connect } from 'react-redux';
import { getOptionFields } from '../../state/general/selectors';
import { getOrderData } from '../../state/orders/selectors';
import { push } from 'connected-react-router';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  stepper: {
    marginBottom: '1em',
  },
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column',
    padding: '1em',
  },
}));

interface NewOrderPageProps {
  push: any
  resetForm: Function;
  setFormData: Function;
  editId?: number;
  optionFields: any;
  setEditOrderData: Function;
  orderData: any;
}

const NewOrderPage: FunctionComponent<NewOrderPageProps> = ({ push, resetForm, setFormData, editId, optionFields, setEditOrderData, orderData }) => {
  const isEditing = editId != null;

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [activeStep, setActiveStep] = useState(isEditing ? 2 : 1);
  const classes = useStyles();

  const isOrderDataEmpty = Object.keys(orderData).length === 0;


  useMount(() => checkLoginState());
  useMount(() => {
    if (!isEditing)
      resetForm()
    else if (isOrderDataEmpty) {
      // we came by direct link and have to fetch and set things first...
      setEditOrderData(editId!)
    }
  });

  const steps = [
    t('order.selectBranchOffice'),
    t('order.enterOrderDetails'),
    t('order.selectProducts'),
    t('order.summary'),
  ];

  const isStepCompleted = (index: number) => activeStep - 1 > index;

  const onStepCompleted = (options: any) => {
    if (activeStep === steps.length) {
      push(`/`);
    } else {
      setActiveStep(activeStep + 1);
    }
  }

  const onBackClick = () => setActiveStep(activeStep - 1);

  const renderStep = () => {
    // we may have to load data first
    if (!optionFields || (isEditing && isOrderDataEmpty))
      return null;

    switch (activeStep) {
      case 1:
        return <Step1 onStepCompleted={onStepCompleted} editId={editId} />
      case 2:
        return <Step2 onStepCompleted={onStepCompleted} onBackClick={onBackClick} editId={editId} />
      case 3:
        return <Step3 onStepCompleted={onStepCompleted} onBackClick={onBackClick} editId={editId} />
      case 4:
        return <Step4 onStepCompleted={onStepCompleted} onBackClick={onBackClick} editId={editId} />
      default:
        return null;
    }
  }

  return <>
    {!isMobileScreen && <Stepper activeStep={activeStep - 1} className={classes.stepper}>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: { optional?: React.ReactNode } = {};
        stepProps.completed = isStepCompleted(index);
        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>}
    {renderStep()}
  </>
}

const mapStateToProps = (state: any) => ({
  optionFields: getOptionFields(state),
  orderData: getOrderData(state),
});


const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  push: (path: string) => dispatch(push(path)),
  resetForm: () => dispatch(reset()),
  setFormData: (obj: any) => dispatch(setOrderData(obj)),
  setEditOrderData: (orderId: number) => dispatch(setEditOrderData(orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderPage);

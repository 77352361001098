import * as actionTypes from './actionTypes';

const initialState = {
    currentUser: null,
    applicationError: null,
    detailEntry: null,
    optionFields: null,
};

export default function reducer(state = initialState, action: any) {
    const { payload } = action;

    switch (action.type) {
        case actionTypes.SET_CURRENT_USER:
            return { ...state, currentUser: payload };

        case actionTypes.SET_APPLICATION_ERROR:
            return { ...state, applicationError: payload };

        case actionTypes.SET_DETAIL_ENTRY:
            return { ...state, detailEntry: payload };

        case actionTypes.SET_OPTION_FIELDS:
            return { ...state, optionFields: payload }

        default:
            return state;

    }
}

import { BranchOffice, PostAddress } from '../../types';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import { Address } from '../Address';
import { Contact } from '../Contact';
import { TextWithSubtext } from '../TextWithSubtext';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  card: {
    maxWidth: '18em',
    '& h6': {
      fontSize: '1.1rem',
    },
    margin: '0.5em',
    display: 'flex',
  },
  content: {
    padding: '0.5em 1em !important',
    flexGrow: 1,
  },
  noGrow: {
    flexGrow: 0,
  },
  fullWidth: {
    maxWidth: 'none',
  },
  code: {
    backgroundColor: '#003b5c',
    fontSize: '1.3em',
    color: '#fff',
    width: '5em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
  }
});

interface BranchOfficeCardProps {
  fullWidth?: boolean;
  simple?: boolean;
  branchOffice: BranchOffice;
  className?: string;
  deviatingDeliveryAddress?: PostAddress;
}

export const BranchOfficeCard: FunctionComponent<BranchOfficeCardProps> = ({ branchOffice, className, fullWidth, simple, deviatingDeliveryAddress }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const hasContact = branchOffice.contact || branchOffice.mail || branchOffice.phone || branchOffice.fax;

  return (
    <Card className={clsx(classes.card, fullWidth ? classes.fullWidth : null, className)} variant="outlined" square>
      <div className={classes.code}>
        {branchOffice.code}
      </div>
      <CardContent className={classes.content}>
        <Typography variant="h6" gutterBottom>
          <TextWithSubtext subtext={branchOffice.name2}>{branchOffice.name}</TextWithSubtext>
        </Typography>
        {simple
          ? <Address city={branchOffice.city} />
          : <Address
            address={branchOffice.address}
            address2={branchOffice.address2}
            postalCode={branchOffice.postalCode}
            city={branchOffice.city}
            county={branchOffice.county}
            country={branchOffice.countryCode} />
        }
      </CardContent>
      {!simple && deviatingDeliveryAddress && <CardContent className={clsx(classes.content, (!simple && hasContact) ? null : classes.noGrow)}>
        <Typography variant="h6" gutterBottom>
          <TextWithSubtext>{t('common:deviatingDeliveryAddress')}</TextWithSubtext>
        </Typography>
        <Address
          address={deviatingDeliveryAddress.address}
          address2={deviatingDeliveryAddress.address2}
          postalCode={deviatingDeliveryAddress.postalCode}
          city={deviatingDeliveryAddress.city}
          county={deviatingDeliveryAddress.county}
          country={deviatingDeliveryAddress.countryCode} />
      </CardContent>}
      {!simple && hasContact && <CardContent className={clsx(classes.content, classes.noGrow)}>
        <Contact
          contact={branchOffice.contact}
          mail={branchOffice.mail}
          phone={branchOffice.phone}
          fax={branchOffice.fax} />
      </CardContent>}
    </Card>
  );
}
import React, { FunctionComponent } from 'react';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import logo from './ipd-logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: 0,
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#4a4a4a',
    listStyle: 'none',
    listStyleType: 'none',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& > li': {
      color: '#4a4a4a',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        padding: '0 5px',
        color: '#ccc',
      }
    },
    '& img': {
      verticalAlign: 'middle',
      width: '29px',
    }
  },
});

interface BreadcrumbProps {
  onClick?: any;
}

export const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({ onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return <ol onClick={onClick} className={classes.root}>
    <li>
      <img src={logo} alt={t('logoIPD')} />
    </li>
    <li>
      <ChevronRightIcon />
      <span>{t('appTitle')}</span>
    </li>
  </ol>
};

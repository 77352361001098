import * as actionTypes from './actionTypes';

import { Order, Position } from '../../types';
import { getData, getPage, getRowsPerPage, isLoading } from './selectors';
import { getOrderById, getOrders } from '../../services/BackendService';

import { find } from 'lodash';
import moment from 'moment';

export const reset = () => ({
  type: actionTypes.RESET,
});

export const setOrderData = (object: any) => ({
  type: actionTypes.SET_ORDER_DATA,
  payload: object,
});

export const setEditOrderData = (orderId: number) => async (dispatch: any, getState: any) => {
  const data = getData(getState());

  // in some cases (e.g. when edit page is opened by direct URL typing) we don't have any order data, so we have to fetch it.
  let editOrder: Order;
  if (data)
    editOrder = find(data.items, { id: orderId });
  else
    editOrder = await getOrderById(orderId);

  if (editOrder) {
    const shoppingCart: any = {};
    editOrder.positions.forEach((pos: Position) => {
      shoppingCart[pos.no] = {
        id: pos.productId,
        no: pos.no,
        description: pos.description,
        description2: pos.description2,
        quantity: pos.quantity,
      }
    });

    return dispatch({
      type: actionTypes.SET_ORDER_DATA,
      payload: {
        branchOffice: editOrder.branchOffice,
        comment: editOrder.comment,
        relocationType: editOrder.relocationType,
        requestedDeliveryDate: moment(editOrder.requestedDeliveryDate),
        newInstallation: editOrder.newInstallation,
        remoteInstallation: editOrder.remoteInstallation,
        offerRequired: editOrder.offerRequired,
        shoppingCart,
        deviatingDeliveryAddress: editOrder.deviatingDeliveryAddress,
      }
    });
  } else {
    throw new Error('Order not found!')
  }
}

export const setPage = (page: number) => (dispatch: any, getState: any) => {
  dispatch({
    type: actionTypes.SET_PAGE,
    payload: page,
  });

  dispatch(fetchData());
};

export const setRowsPerPage = (rowsPerPage: number) => (dispatch: any, getState: any) => {
  dispatch({
    type: actionTypes.SET_ROWS_PER_PAGE,
    payload: rowsPerPage,
  });

  dispatch(fetchData());
};

export const fetchData = () => (dispatch: any, getState: any) => {
  const state = getState();

  if (isLoading(state))
    return;

  dispatch({
    type: actionTypes.FETCH_DATA_REQUEST,
  });

  return getOrders(getPage(state), getRowsPerPage(state))
    .then(result => {
      return dispatch({
        type: actionTypes.FETCH_DATA_SUCCESS,
        payload: result,
      });
    })
    .catch((error: any) => dispatch({
      type: actionTypes.FETCH_DATA_FAILURE,
      payload: error.message,
    }));
}


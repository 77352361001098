import { Button, FormControl, FormControlLabel, Grid, InputLabel, NativeSelect, Switch, TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LngStringSet, PostAddress } from '../../types';
import React, { FunctionComponent, useCallback, useState } from 'react';
import moment, { Moment } from 'moment';

import { Action } from 'redux';
import { AddressForm } from '../../components/AddressForm';
import MomentUtils from '@date-io/moment';
import { PageHeader } from '../../components/PageHeader';
import { StepButtonRow } from '../../components/styled';
import { StepProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { getOptionFieldLanguageKey } from '../../utils';
import { getOptionFields } from '../../state/general/selectors';
import { getOrderData } from '../../state/orders/selectors';
import { map } from 'lodash';
import { setOrderData } from '../../state/orders/actions';
import { useTranslation } from 'react-i18next';

const Step2: FunctionComponent<StepProps> = ({
  onStepCompleted,
  onBackClick,
  orderData,
  setOrderData,
  optionFields,
}) => {
  const { t, i18n } = useTranslation();
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState<Moment | null>(orderData.requestedDeliveryDate || moment().add(1, 'day'));
  const [comment, setComment] = useState<string>(orderData.comment);
  const [newInstallation, setNewInstallation] = useState<boolean>(orderData.newInstallation === true);
  const [remoteInstallation, setRemoteInstallation] = useState<boolean>(orderData.remoteInstallation === true);
  const [offerRequired, setOfferRequired] = useState<boolean>(orderData.offerRequired === true);
  const [relocationType, setRelocationType] = useState<number>(orderData.relocationType || Object.keys(optionFields.relocationType)[0]);
  const [address, setAddress] = useState<PostAddress | undefined>(orderData.deviatingDeliveryAddress);

  // Deviating Delivery Address
  const [deviatingAddress, setDeviatingAddress] = useState<boolean>(orderData.deviatingDeliveryAddress != null);

  const handleDateChange = (date: Moment | null) => setRequestedDeliveryDate(date);

  const handleRelocationTypeChange = (target: any) =>
    setRelocationType(parseInt(target.selectedOptions[0].value))

  const handleAddressChange = useCallback((address: PostAddress) => setAddress(address), []);

  const complete = () => {
    const addressEmpty = address && (
      address.address.trim() === ''
      && address.address2.trim() === ''
      && address.postalCode.trim() === ''
      && address.city.trim() === ''
      && (address.county || '').trim() === ''
      && (address.countryCode || '').trim() === ''
    );
    setOrderData({
      comment,
      relocationType,
      requestedDeliveryDate,
      newInstallation,
      remoteInstallation,
      offerRequired,
      deviatingDeliveryAddress: deviatingAddress && !addressEmpty ? address : null,
    });
    onStepCompleted();
  }

  const isValid = (requestedDeliveryDate != null)

  return <>
    <PageHeader dense>{t('order.enterOrderDetails')}</PageHeader>
    <StepButtonRow>
      <Button variant="contained" color="default" onClick={onBackClick ? () => onBackClick() : undefined}>{t('common:back')}</Button>
      <Button variant="contained" color="primary" onClick={complete} disabled={!isValid}>{t('common:next')}</Button>
    </StepButtonRow>
    <Grid container justify="center" spacing={2} alignItems="stretch">
      <Grid item xs={12} sm={6}>
        <TextField
          label={t('common:comment')}
          value={comment}
          onChange={(event: any) => setComment(event.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        {optionFields && <FormControl fullWidth>
          <InputLabel htmlFor="relocationType">{t('common:relocationType')}</InputLabel>
          <NativeSelect
            value={relocationType}
            onChange={(event: any) => handleRelocationTypeChange(event.target)}
            inputProps={{ id: 'relocationType' }}
          >
            {map((optionFields.relocationType as any), (strings: LngStringSet, value: string) => <option value={value} key={value}>{strings[getOptionFieldLanguageKey(i18n.language)]}</option>)}
          </NativeSelect>
        </FormControl>}
      </Grid>
      <Grid item xs={12} sm={3}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={moment.locale()}>
          <DatePicker
            variant="inline"
            format="LL"
            label={t('common:requestedDeliveryDate')}
            value={requestedDeliveryDate}
            onChange={handleDateChange}
            fullWidth
            minDate={moment().add(1, 'day').startOf('day').toISOString()}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Switch onChange={(event: any, newState: boolean) => setNewInstallation(newState)} checked={newInstallation} color="primary" />}
          label={t('common:newInstallation')}
        />
        <FormControlLabel
          control={<Switch onChange={(event: any, newState: boolean) => setRemoteInstallation(newState)} checked={remoteInstallation} color="primary" />}
          label={t('common:remoteInstallation')}
        />
        <FormControlLabel
          control={<Switch onChange={(event: any, newState: boolean) => setOfferRequired(newState)} checked={offerRequired} color="primary" />}
          label={t('common:offerRequired')}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={<Switch onChange={(event: any, newState: boolean) => setDeviatingAddress(newState)} checked={deviatingAddress} color="primary" />}
          label={t('common:deviatingDeliveryAddress')}
        />
      </Grid>
      {deviatingAddress &&
        <Grid item xs={12}>
          <AddressForm onChange={handleAddressChange} address={address} />
        </Grid>
      }
    </Grid>
  </>
}

const mapStateToProps = (state: any) => ({
  orderData: getOrderData(state),
  optionFields: getOptionFields(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  setOrderData: (data: any) => dispatch(setOrderData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);

import React, { FunctionComponent } from 'react';

import { PageHeader } from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';

export const NotFoundPage: FunctionComponent = () => {
  const { t } = useTranslation();
  return <>
    <PageHeader dense>{t('notFound.title')}</PageHeader>
    {t('notFound.message')}
  </>;
}
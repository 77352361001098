import React, { FunctionComponent } from 'react';

import { Action } from 'redux';
import { BranchOffice } from '../../types';
import BranchOfficePicker from '../../components/BranchOfficePicker';
import { PageHeader } from '../../components/PageHeader';
import { StepProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { getOrderData } from '../../state/orders/selectors';
import { setOrderData } from '../../state/orders/actions';
import { useTranslation } from 'react-i18next';

const Step1: FunctionComponent<StepProps> = ({
  onStepCompleted,
  orderData,
  setOrderData,
 }) => {
  const { t } = useTranslation();

  const onOfficeSelected = (selection: BranchOffice) => {
    setOrderData(selection);
    onStepCompleted();
  }

  return <>
    <PageHeader dense>{t('order.selectBranchOffice')}</PageHeader>
    <BranchOfficePicker onSelected={onOfficeSelected} />
  </>
}

const mapStateToProps = (state: any) => ({
  orderData: getOrderData(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  setOrderData: (branchOffice: BranchOffice) => dispatch(setOrderData({ branchOffice })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);

import React, { FunctionComponent } from 'react';

import { Typography } from '@material-ui/core';

interface TextWithSubtextProps {
  children: string;
  subtext?: string;
}

export const TextWithSubtext: FunctionComponent<TextWithSubtextProps> = ({ children, subtext }) =>
  <>
    <Typography variant="body1" component="p">
      {children}
    </Typography>
    {subtext && <Typography variant="body2" component="p">
      {subtext}
    </Typography>}
  </>;